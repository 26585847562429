import React from 'react'
import Image from '../images/alex-rich--xrPDM8jT7k-unsplash.jpg'
import './homepg.css'
import { Link } from 'react-router-dom';
function AboutusComp() {
  return (
    <div className="container-fluid ">
      <div className="row justify-content-center">

        <div className="col-md-12 col-lg-5 order-2 order-lg-1 txt-box p-0 p-sm-0 py-lg-3 d-flex">
          <div className="p-lg-3 px-lg-4 p-2 text-box-about-us">
            <h5>ABOUT US</h5>
            <h2>An Established And Reputable Company</h2>
            <p>
              Empower PK is a provider of reliable power solutions tailored to meet the diverse needs of homes, businesses, and industries.
            </p>
            
            <Link to="/aboutus">
              <button className='button'>
                Learn More
              </button>
            </Link>
          </div>
        </div>

        <div className="col-md-12 col-lg-7 order-1 order-lg-2  p-lg-2 p-md-0 p-sm-0 p-0 ">
          <div className='Image-container '>
            <img src={Image} alt="Power Solutions" />
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default AboutusComp