import { BrowserRouter,Routes, Route } from "react-router-dom"
import Home from './pages/Home'
import Contact from './pages/Contact'
import AboutUs from './pages/Aboutus'
import AfterSales from "./pages/AfterSales"
import Navbar from './components/Nav2'
import Product from "./pages/Product"
import Footer from "./components/Footer"
import InternationalCollabration from "./pages/InternationalCollabration"

function App() {
  return (
    <div>
       <BrowserRouter>
        <Navbar />
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="aboutus" element={ <AboutUs/> } />
        <Route path="aftersales" element={ <AfterSales/> } />
        <Route path="/product/:productName" element={ <Product/> } />
        <Route path="contact" element={ <Contact/> } />
        <Route path="internationalcollaboration" element={ <InternationalCollabration/> } />
      </Routes>
      <Footer />

      </BrowserRouter>

    </div>
  );
}

export default App;
