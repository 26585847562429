import React from 'react';
import staff from '../images/staff.png';
import solar from '../images/solar-panel.png';
import calender from '../images/calendar.png';
import generator from '../images/generator.png';
import agreement from '../images/agreement.png';
import './homepg.css';

function Experience() {
  return (
    <div className='container-fluid'>
      <div className='row justify-content-center mt-lg-4 mb-lg-3'>

        <div className='col-lg-2 col-md-4 col-sm-4 col-4 exp-box mt-lg-3 mt-md-2 mt-sm-1'>
          <div className='img-container bg-calender'>
            <img src={calender} className='exp-img img-fluid' alt='Calendar' />
          </div>
          <h5 className='text-center mt-2 num '>14+</h5>
          <p className='text-center text-uppercase '>
            Years of <br /> experience
          </p>
        </div>

        <div className='col-lg-2 col-md-4 col-sm-4 col-4 exp-box mt-lg-3 mt-md-2 mt-sm-1'>
          <div className='img-container bg-staff'>
            <img src={staff} className='exp-img img-fluid' alt='Staff' />
          </div>
          <h5 className='text-center mt-2 num fs-3 '>100+</h5>
          <p className='text-center text-uppercase '>
            Dedicated <br /> employees
          </p>
        </div>

        <div className='col-lg-2 col-md-4 col-sm-4 col-4 exp-box mt-lg-3 mt-md-2 mt-sm-1'>
          <div className='img-container bg-agreement'>
            <img src={agreement} className='exp-img img-fluid' alt='Agreement' />
          </div>
          <h5 className='text-center mt-2 num '>650+</h5>
          <p className='text-center text-uppercase '>
            Happy <br /> customers
          </p>
        </div>

        <div className='col-lg-2 col-md-4 col-sm-4 col-4 exp-box mt-lg-3 mt-md-2 mt-sm-1'>
          <div className='img-container bg-generator'>
            <img src={generator} className='exp-img img-fluid' alt='Generator' />
          </div>
          <h5 className='text-center mt-2 num '>1180+</h5>
          <p className='text-center text-uppercase '>
            Generator <br /> installation
          </p>
        </div>

        <div className='col-lg-2 col-md-4 col-sm-4 col-4 exp-box mt-lg-3 mt-md-2 mt-sm-1'>
          <div className='img-container bg-solar'>
            <img src={solar} className='exp-img img-fluid' alt='Solar Panel' />
          </div>
          <h5 className='text-center mt-2 num '>25+</h5>
          <p className='text-center text-uppercase '>
            Solar <br /> installation
          </p>
        </div>
      </div>
    </div>
  );
}

export default Experience;
