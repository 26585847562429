import React from 'react';
import Card from './Card';
import Generator from '../images/iqram-o-dowla-shawon-Wk2to7RYKHo-unsplash.jpg';
import ToolsandUtilities from '../images/sht-0821-Eyeonsafety-2.jpg';
import Solar from '../images/Solar-Panels-2.jpg';
import GreenSolutions from '../images/In_page_festival_lichtjes-495x321.jpg';
import Indenting from '../images/Automotive-OEM-Parts-Sourcing.jpg';

function ProductRangeComp() {
  const cards = [
    {
      title: "Diesel Generators",
      text: "Collaboration with industry giants like Perkins, Yang Dong, Cummins, Volvo etc. to ensure safe and reliable power generation.",
      image: Generator,
    },
    {
      title: "Tools & Utilities",
      text: "Top-of-the-line tools including air compressors, welding equipment, and cutting tools are available at Empower.",
      image: ToolsandUtilities,
    },
    {
      title: "Solar Solutions",
      text: "Provides state-of-the-art panels, efficient control systems, and dependable after-sales services, ensuring  customer satisfaction.",
      image: Solar,
    },
    {
      title: "Green Solutions",
      text: "Makes use of BESS Energy Storage System, Green Hydrogen, Waste to Energy Solution and more.",
      image: GreenSolutions,
    },
    {
      title: "Indenting",
      text: "Specializes in expert indenting of OEM parts and machinery.",
      image: Indenting,
    },
  ];

  return (
    <div className="container-fluid justify-content-center align-items-center card-component p-2 pt-4 mt-lg-3 pb-4  mt-md-2 mmt-0">
        <h3 className="lead text-center">check our services</h3>
        <h1 className="display-6 text-center mb-0">Business Solutions</h1>
      <div className="row d-flex flex-wrap py-lg-4 p-md-1 p-sm-0 p-0 mt-0 m-sm- m-1 justify-content-center ">
        {cards.map((card, index) => (
          <div key={index} className="col-12 col-md-2 col-lg-2 p-sm-0 mt-1 m-lg-2 m-md-2 ">
            <Card title={card.title} text={card.text} image={card.image} />
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductRangeComp;
